* {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
